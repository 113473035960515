import assert from "./assert";
import { bundleWorker } from "./bundle";
import { fetchListResult, fetchResult } from "./cfetch";
import { createWorkerUploadForm } from "./create-worker-upload-form";
import { getMigrationsToUpload } from "./durable";
import { logger } from "./logger";
import { getMetricsUsageHeaders } from "./metrics";
import { syncAssets } from "./sites";
import { identifyD1BindingsAsBeta } from "./worker";
import { getZoneForRoute } from "./zones";
import type { Config } from "./config";
import type {
	Route,
	ZoneIdRoute,
	ZoneNameRoute,
	CustomDomainRoute,
} from "./config/environment";
import type { Entry } from "./entry";
import type { AssetPaths } from "./sites";
import type { CfWorkerInit } from "./worker";

type Props = {
	config: Config;
	accountId: string | undefined;
	entry: Entry;
	rules: Config["rules"];
	name: string | undefined;
	env: string | undefined;
	compatibilityDate: string | undefined;
	compatibilityFlags: string[] | undefined;
	assetPaths: AssetPaths | undefined;
	vars: Record<string, string> | undefined;
	defines: Record<string, string> | undefined;
	triggers: string[] | undefined;
	routes: string[] | undefined;
	legacyEnv: boolean | undefined;
	jsxFactory: string | undefined;
	jsxFragment: string | undefined;
	tsconfig: string | undefined;
	isWorkersSite: boolean;
	minify: boolean | undefined;
	nodeCompat: boolean | undefined;
	outDir: string | undefined;
	dryRun: boolean | undefined;
	noBundle: boolean | undefined;
	keepVars: boolean | undefined;
	wasmModule: string;
};

type RouteObject = ZoneIdRoute | ZoneNameRoute | CustomDomainRoute;

export type CustomDomain = {
	id: string;
	zone_id: string;
	zone_name: string;
	hostname: string;
	service: string;
	environment: string;
};
type UpdatedCustomDomain = CustomDomain & { modified: boolean };
type ConflictingCustomDomain = CustomDomain & {
	external_dns_record_id?: string;
	external_cert_id?: string;
};

export type CustomDomainChangeset = {
	added: CustomDomain[];
	removed: CustomDomain[];
	updated: UpdatedCustomDomain[];
	conflicting: ConflictingCustomDomain[];
};

function sleep(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

function renderRoute(route: Route): string {
	let result = "";
	if (typeof route === "string") {
		result = route;
	} else {
		result = route.pattern;
		const isCustomDomain = Boolean(
			"custom_domain" in route && route.custom_domain
		);
		if (isCustomDomain && "zone_id" in route) {
			result += ` (custom domain - zone id: ${route.zone_id})`;
		} else if (isCustomDomain && "zone_name" in route) {
			result += ` (custom domain - zone name: ${route.zone_name})`;
		} else if (isCustomDomain) {
			result += ` (custom domain)`;
		} else if ("zone_id" in route) {
			result += ` (zone id: ${route.zone_id})`;
		} else if ("zone_name" in route) {
			result += ` (zone name: ${route.zone_name})`;
		}
	}
	return result;
}

// publishing to custom domains involves a few more steps than just updating
// the routing table, and thus the api implementing it is fairly defensive -
// it will error eagerly on conflicts against existing domains or existing
// managed DNS records

// however, you can pass params to override the errors. to know if we should
// override the current state, we generate a "changeset" of required actions
// to get to the state we want (specified by the list of custom domains). the
// changeset returns an "updated" collection (existing custom domains
// connected to other scripts) and a "conflicting" collection (the requested
// custom domains that have a managed, conflicting DNS record preventing the
// host's use as a custom domain). with this information, we can prompt to
// the user what will occur if we create the custom domains requested, and
// add the override param if they confirm the action
//
// if a user does not confirm that they want to override, we skip publishing
// to these custom domains, but continue on through the rest of the
// publish stage
async function publishCustomDomains(
	workerUrl: string,
	accountId: string,
	domains: Array<RouteObject>
): Promise<string[]> {
	const config = {
		override_scope: true,
		override_existing_origin: false,
		override_existing_dns_record: false,
	};
	const origins = domains.map((domainRoute) => {
		return {
			hostname: domainRoute.pattern,
			zone_id: "zone_id" in domainRoute ? domainRoute.zone_id : undefined,
			zone_name: "zone_name" in domainRoute ? domainRoute.zone_name : undefined,
		};
	});

	const fail = () => {
		return [
			domains.length > 1
				? `Publishing to ${domains.length} Custom Domains was skipped, fix conflicts and try again`
				: `Publishing to Custom Domain "${domains[0].pattern}" was skipped, fix conflict and try again`,
		];
	};

	// if (!process.stdout.isTTY) {
	// running in non-interactive mode.
	// existing origins / dns records are not indicative of errors,
	// so we aggressively update rather than aggressively fail
	config.override_existing_origin = true;
	config.override_existing_dns_record = true;
	// }
	// publish to domains
	await fetchResult(`${workerUrl}/domains/records`, {
		method: "PUT",
		body: JSON.stringify({ ...config, origins }),
		headers: {
			"Content-Type": "application/json",
		},
	});

	return domains.map((domain) => renderRoute(domain));
}

export default async function publish(
	props: Props,
	vfs: Record<string, string>
): Promise<void> {
	// TODO: warn if git/hg has uncommitted changes
	const { config, accountId } = props;

	if (!(props.compatibilityDate || config.compatibility_date)) {
		const compatibilityDateStr = `${new Date().getFullYear()}-${(
			new Date().getMonth() +
			1 +
			""
		).padStart(2, "0")}-${(new Date().getDate() + "").padStart(2, "0")}`;

		throw new Error(`A compatibility_date is required when publishing. Add the following to your wrangler.toml file:.
    \`\`\`
    compatibility_date = "${compatibilityDateStr}"
    \`\`\`
    Or you could pass it in your terminal as \`--compatibility-date ${compatibilityDateStr}\`
See https://developers.cloudflare.com/workers/platform/compatibility-dates for more information.`);
	}

	const triggers = props.triggers || config.triggers?.crons;
	const routes =
		props.routes ?? config.routes ?? (config.route ? [config.route] : []) ?? [];
	const routesOnly: Array<Route> = [];
	const customDomainsOnly: Array<RouteObject> = [];
	for (const route of routes) {
		if (typeof route !== "string" && route.custom_domain) {
			if (route.pattern.includes("*")) {
				throw new Error(
					`Cannot use "${route.pattern}" as a Custom Domain; wildcard operators (*) are not allowed`
				);
			}
			if (route.pattern.includes("/")) {
				throw new Error(
					`Cannot use "${route.pattern}" as a Custom Domain; paths are not allowed`
				);
			}
			customDomainsOnly.push(route);
		} else {
			routesOnly.push(route);
		}
	}

	// deployToWorkersDev defaults to true only if there aren't any routes defined
	const deployToWorkersDev = config.workers_dev ?? routes.length === 0;

	const jsxFactory = props.jsxFactory || config.jsx_factory;
	const jsxFragment = props.jsxFragment || config.jsx_fragment;
	const keepVars = props.keepVars ?? config.keep_vars;

	const minify = props.minify ?? config.minify;

	const scriptName = props.name;
	assert(
		scriptName,
		'You need to provide a name when publishing a worker. Either pass it as a cli arg with `--name <name>` or in your config file as `name = "<name>"`'
	);

	assert(
		!config.site || config.site.bucket,
		"A [site] definition requires a `bucket` field with a path to the site's assets directory."
	);

	// if (props.outDir) {
	// 	// we're using a custom output directory,
	// 	// so let's first ensure it exists
	// 	mkdirSync(props.outDir, { recursive: true });
	// 	// add a README
	// 	const readmePath = path.join(props.outDir, "README.md");
	// 	writeFileSync(
	// 		readmePath,
	// 		`This folder contains the built output assets for the worker "${scriptName}" generated at ${new Date().toISOString()}.`
	// 	);
	// }

	// const destination = props.outDir ?? (await tmp.dir({ unsafeCleanup: true }));
	const envName = props.env ?? "production";

	const start = Date.now();
	const notProd = Boolean(!props.legacyEnv && props.env);
	const workerName = notProd ? `${scriptName} (${envName})` : scriptName;
	const workerUrl = notProd
		? `/accounts/${accountId}/workers/services/${scriptName}/environments/${envName}`
		: `/accounts/${accountId}/workers/scripts/${scriptName}`;

	let available_on_subdomain; // we'll set this later

	// try {
	// if (props.noBundle) {
	// 	// if we're not building, let's just copy the entry to the destination directory
	// 	const destinationDir =
	// 		typeof destination === "string" ? destination : destination.path;
	// 	mkdirSync(destinationDir, { recursive: true });
	// 	writeFileSync(
	// 		path.join(destinationDir, path.basename(props.entry.file)),
	// 		readFileSync(props.entry.file, "utf-8")
	// 	);
	// }

	const result = await bundleWorker(
		props.entry,
		vfs,
		{
			serveAssetsFromWorker: !props.isWorkersSite && Boolean(props.assetPaths),
			betaD1Shims: identifyD1BindingsAsBeta(config.d1_databases)?.map(
				(db) => db.binding
			),
			jsxFactory,
			jsxFragment,
			rules: props.rules,
			tsconfig: props.tsconfig ?? config.tsconfig,
			minify,
			nodeCompat: false,
			define: { ...config.define, ...props.defines },
			checkFetch: false,
			assets: config.assets && {
				...config.assets,
				// enable the cache when publishing
				bypassCache: false,
			},
			services: config.services,
			// We don't set workerDefinitions here,
			// because we don't want to apply the dev-time
			// facades on top of it
			workerDefinitions: undefined,
			firstPartyWorkerDevFacade: false,
			// We want to know if the build is for development or publishing
			// This could potentially cause issues as we no longer have identical behaviour between dev and publish?
			targetConsumer: "publish",
			local: false,
			experimentalLocalStubCache: false,
		},
		props.wasmModule
	);

	// durable object migrations
	const migrations = !props.dryRun
		? await getMigrationsToUpload(scriptName!, {
				accountId,
				config,
				legacyEnv: props.legacyEnv,
				env: props.env,
		  })
		: undefined;

	// 	const assets = await syncAssets(
	// 		accountId,
	// 		// When we're using the newer service environments, we wouldn't
	// 		// have added the env name on to the script name. However, we must
	// 		// include it in the kv namespace name regardless (since there's no
	// 		// concept of service environments for kv namespaces yet).
	// 		scriptName + (!props.legacyEnv && props.env ? `-${props.env}` : ""),
	// 		props.assetPaths,
	// 		false,
	// 		props.dryRun
	// 	);

	const bindings: CfWorkerInit["bindings"] = {
		kv_namespaces: (config.kv_namespaces || []).concat(
			// assets.namespace
			// 	? { binding: "__STATIC_CONTENT", id: assets.namespace }
			// 	: []
			[]
		),
		vars: { ...config.vars, ...props.vars },
		wasm_modules: config.wasm_modules,
		text_blobs: {
			...config.text_blobs,
			// ...(assets.manifest &&
			// 	format === "service-worker" && {
			// 		__STATIC_CONTENT_MANIFEST: "__STATIC_CONTENT_MANIFEST",
			// 	}),
		},
		data_blobs: config.data_blobs,
		durable_objects: config.durable_objects,
		r2_buckets: config.r2_buckets,
		d1_databases: identifyD1BindingsAsBeta(config.d1_databases),
		services: config.services,
		dispatch_namespaces: config.dispatch_namespaces,
		logfwdr: config.logfwdr,
		unsafe: config.unsafe?.bindings,
	};

	// 	if (assets.manifest) {
	// 		modules.push({
	// 			name: "__STATIC_CONTENT_MANIFEST",
	// 			content: JSON.stringify(assets.manifest),
	// 			type: "text",
	// 		});
	// 	}

	const worker: CfWorkerInit = {
		name: scriptName,
		main: {
			name: scriptName,
			content: new TextDecoder().decode(result.outputFiles[0].contents),
			// type: bundleType,
		},
		bindings,
		migrations,
		// modules,
		compatibility_date: props.compatibilityDate ?? config.compatibility_date,
		compatibility_flags: props.compatibilityFlags ?? config.compatibility_flags,
		usage_model: config.usage_model,
		keepVars,
	};

	// // As this is not deterministic for testing, we detect if in a jest environment and run asynchronously
	// // We do not care about the timing outside of testing
	// const bundleSizePromise = printBundleSize(
	// 	{ name: path.basename(resolvedEntryPointPath), content: content },
	// 	modules
	// );
	// if (process.env.JEST_WORKER_ID !== undefined) await bundleSizePromise;
	// else void bundleSizePromise;

	const withoutStaticAssets = {
		...bindings,
		kv_namespaces: config.kv_namespaces,
		text_blobs: config.text_blobs,
	};

	// mask anything that was overridden in cli args
	// so that we don't log potential secrets into the terminal
	const maskedVars = { ...withoutStaticAssets.vars };
	for (const key of Object.keys(maskedVars)) {
		if (maskedVars[key] !== config.vars[key]) {
			// This means it was overridden in cli args
			// so let's mask it
			maskedVars[key] = "(hidden)";
		}
	}

	// printBindings({ ...withoutStaticAssets, vars: maskedVars });

	if (!props.dryRun) {
		// Upload the script so it has time to propagate.
		// We can also now tell whether available_on_subdomain is set
		const result = await fetchResult<{
			available_on_subdomain: boolean;
			id: string | null;
			etag: string | null;
			pipeline_hash: string | null;
		}>(
			workerUrl,
			{
				method: "PUT",
				body: createWorkerUploadForm(worker),
				// headers: await getMetricsUsageHeaders(config.send_metrics),
			},
			new URLSearchParams({
				include_subdomain_availability: "true",
				// pass excludeScript so the whole body of the
				// script doesn't get included in the response
				excludeScript: "true",
			})
		);

		available_on_subdomain = result.available_on_subdomain;

		// if (config.first_party_worker) {
		// 	// Print some useful information returned after publishing
		// 	// Not all fields will be populated for every worker
		// 	// These fields are likely to be scraped by tools, so do not rename
		// 	if (result.id) logger.log("Worker ID: ", result.id);
		// 	if (result.etag) logger.log("Worker ETag: ", result.etag);
		// 	if (result.pipeline_hash)
		// 		logger.log("Worker PipelineHash: ", result.pipeline_hash);
		// }
	}
	// } finally {
	// 	// if (typeof destination !== "string") {
	// 	// 	// this means we're using a temp dir,
	// 	// 	// so let's clean up before we proceed
	// 	// 	await destination.cleanup();
	// 	// }
	// }

	// if (props.dryRun) {
	// 	logger.log(`--dry-run: exiting now.`);
	// 	return;
	// }
	assert(accountId, "Missing accountId");

	const uploadMs = Date.now() - start;
	const deployments: Promise<string[]>[] = [];

	if (deployToWorkersDev) {
		// Deploy to a subdomain of `workers.dev`
		const userSubdomain = await getSubdomain(accountId);
		const scriptURL =
			props.legacyEnv || !props.env
				? `${scriptName}.${userSubdomain}.workers.dev`
				: `${envName}.${scriptName}.${userSubdomain}.workers.dev`;
		if (!available_on_subdomain) {
			// Enable the `workers.dev` subdomain.
			deployments.push(
				fetchResult(`${workerUrl}/subdomain`, {
					method: "POST",
					body: JSON.stringify({ enabled: true }),
					headers: {
						"Content-Type": "application/json",
					},
				})
					.then(() => [scriptURL])
					// Add a delay when the subdomain is first created.
					// This is to prevent an issue where a negative cache-hit
					// causes the subdomain to be unavailable for 30 seconds.
					// This is a temporary measure until we fix this on the edge.
					.then(async (url) => {
						await sleep(3000);
						return url;
					})
			);
		} else {
			deployments.push(Promise.resolve([scriptURL]));
		}
	} else {
		if (available_on_subdomain) {
			// Disable the workers.dev deployment
			await fetchResult(`${workerUrl}/subdomain`, {
				method: "POST",
				body: JSON.stringify({ enabled: false }),
				headers: {
					"Content-Type": "application/json",
				},
			});
		}
	}

	logger.log("Uploaded", workerName, formatTime(uploadMs));

	// Update routing table for the script.
	if (routesOnly.length > 0) {
		deployments.push(
			publishRoutes(routesOnly, { workerUrl, scriptName, notProd }).then(() => {
				if (routesOnly.length > 10) {
					return routesOnly
						.slice(0, 9)
						.map((route) => renderRoute(route))
						.concat([`...and ${routesOnly.length - 10} more routes`]);
				}
				return routesOnly.map((route) => renderRoute(route));
			})
		);
	}

	// Update custom domains for the script
	if (customDomainsOnly.length > 0) {
		deployments.push(
			publishCustomDomains(workerUrl, accountId, customDomainsOnly)
		);
	}

	// Configure any schedules for the script.
	// TODO: rename this to `schedules`?
	if (triggers && triggers.length) {
		deployments.push(
			fetchResult(`${workerUrl}/schedules`, {
				// Note: PUT will override previous schedules on this script.
				method: "PUT",
				body: JSON.stringify(triggers.map((cron) => ({ cron }))),
				headers: {
					"Content-Type": "application/json",
				},
			}).then(() => triggers.map((trigger) => `schedule: ${trigger}`))
		);
	}

	const targets = await Promise.all(deployments);
	const deployMs = Date.now() - start - uploadMs;

	if (deployments.length > 0) {
		logger.log("Published", workerName, formatTime(deployMs));
		for (const target of targets.flat()) {
			// Append protocol only on workers.dev domains
			logger.log(
				" ",
				(target.endsWith("workers.dev") ? "https://" : "") + target
			);
		}
	} else {
		logger.log("No publish targets for", workerName, formatTime(deployMs));
	}
}

function formatTime(duration: number) {
	return `(${(duration / 1000).toFixed(2)} sec)`;
}

async function getSubdomain(accountId: string): Promise<string> {
	try {
		const { subdomain } = await fetchResult(
			`/accounts/${accountId}/workers/subdomain`
		);
		return subdomain;
	} catch (e) {
		const error = e as { code?: number };
		if (typeof error === "object" && !!error && error.code === 10007) {
			// 10007 error code: not found
			// https://api.cloudflare.com/#worker-subdomain-get-subdomain

			const errorMessage =
				"Error: You need to register a workers.dev subdomain before publishing to workers.dev";
			const solutionMessage =
				"You can either publish your worker to one or more routes by specifying them in wrangler.toml, or register a workers.dev subdomain here:";
			const onboardingLink = `https://dash.cloudflare.com/${accountId}/workers/onboarding`;

			throw new Error(`${errorMessage}\n${solutionMessage}\n${onboardingLink}`);
		} else {
			throw e;
		}
	}
}

/**
 * Associate the newly deployed Worker with the given routes.
 */
async function publishRoutes(
	routes: Route[],
	{
		workerUrl,
		scriptName,
		notProd,
	}: { workerUrl: string; scriptName: string; notProd: boolean }
): Promise<string[]> {
	try {
		return await fetchResult(`${workerUrl}/routes`, {
			// Note: PUT will delete previous routes on this script.
			method: "PUT",
			body: JSON.stringify(
				routes.map((route) =>
					typeof route !== "object" ? { pattern: route } : route
				)
			),
			headers: {
				"Content-Type": "application/json",
			},
		});
	} catch (e) {
		if (isAuthenticationError(e)) {
			// An authentication error is probably due to a known issue,
			// where the user is logged in via an API token that does not have "All Zones".
			return await publishRoutesFallback(routes, { scriptName, notProd });
		} else {
			throw e;
		}
	}
}

/**
 * Try updating routes for the Worker using a less optimal zone-based API.
 *
 * Compute match zones to the routes, then for each route attempt to connect it to the Worker via the zone.
 */
async function publishRoutesFallback(
	routes: Route[],
	{ scriptName, notProd }: { scriptName: string; notProd: boolean }
) {
	if (notProd) {
		throw new Error(
			"Service environments combined with an API token that doesn't have 'All Zones' permissions is not supported.\n" +
				"Either turn off service environments by setting `legacy_env = true`, creating an API token with 'All Zones' permissions, or logging in via OAuth"
		);
	}
	logger.warn(
		"The current authentication token does not have 'All Zones' permissions.\n" +
			"Falling back to using the zone-based API endpoint to update each route individually.\n" +
			"Note that there is no access to routes associated with zones that the API token does not have permission for.\n" +
			"Existing routes for this Worker in such zones will not be deleted."
	);

	const deployedRoutes: string[] = [];

	// Collect the routes (and their zones) that will be deployed.
	const activeZones = new Map<string, string>();
	const routesToDeploy = new Map<string, string>();
	for (const route of routes) {
		const zone = await getZoneForRoute(route);
		if (zone) {
			activeZones.set(zone.id, zone.host);
			routesToDeploy.set(
				typeof route === "string" ? route : route.pattern,
				zone.id
			);
		}
	}

	// Collect the routes that are already deployed.
	const allRoutes = new Map<string, string>();
	const alreadyDeployedRoutes = new Set<string>();
	for (const [zone, host] of activeZones) {
		try {
			for (const { pattern, script } of await fetchListResult<{
				pattern: string;
				script: string;
			}>(`/zones/${zone}/workers/routes`)) {
				allRoutes.set(pattern, script);
				if (script === scriptName) {
					alreadyDeployedRoutes.add(pattern);
				}
			}
		} catch (e) {
			if (isAuthenticationError(e)) {
				e.notes.push({
					text: `This could be because the API token being used does not have permission to access the zone "${host}" (${zone}).`,
				});
			}
			throw e;
		}
	}

	// Deploy each route that is not already deployed.
	for (const [routePattern, zoneId] of routesToDeploy.entries()) {
		if (allRoutes.has(routePattern)) {
			const knownScript = allRoutes.get(routePattern);
			if (knownScript === scriptName) {
				// This route is already associated with this worker, so no need to hit the API.
				alreadyDeployedRoutes.delete(routePattern);
				continue;
			} else {
				throw new Error(
					`The route with pattern "${routePattern}" is already associated with another worker called "${knownScript}".`
				);
			}
		}

		const { pattern } = await fetchResult(`/zones/${zoneId}/workers/routes`, {
			method: "POST",
			body: JSON.stringify({
				pattern: routePattern,
				script: scriptName,
			}),
			headers: {
				"Content-Type": "application/json",
			},
		});

		deployedRoutes.push(pattern);
	}

	if (alreadyDeployedRoutes.size) {
		logger.warn(
			"Previously deployed routes:\n" +
				"The following routes were already associated with this worker, and have not been deleted:\n" +
				[...alreadyDeployedRoutes.values()].map((route) => ` - "${route}"\n`) +
				"If these routes are not wanted then you can remove them in the dashboard."
		);
	}

	return deployedRoutes;
}

function isAuthenticationError(e: unknown): e is ParseError {
	return e instanceof ParseError && (e as { code?: number }).code === 10000;
}

import { fetchInternal, performApiFetch } from "./internal";
import type { RequestInit } from "undici";

// Check out https://api.cloudflare.com/ for API docs.

export { getCloudflareAPIBaseURL as getCloudflareApiBaseUrl } from "./internal";

export interface FetchError {
	code: number;
	message: string;
	error_chain?: FetchError[];
}
export interface FetchResult<ResponseType = unknown> {
	success: boolean;
	result: ResponseType;
	errors: FetchError[];
	messages: string[];
	result_info?: unknown;
}

/**
 * Make a fetch request, and extract the `result` from the JSON response.
 */
export async function fetchResult<ResponseType>(
	resource: string,
	init: RequestInit = {},
	queryParams?: URLSearchParams,
	abortSignal?: AbortSignal
): Promise<ResponseType> {
	const json = await fetchInternal<FetchResult<ResponseType>>(
		resource,
		init,
		queryParams,
		abortSignal
	);
	if (json.success) {
		return json.result;
	} else {
		throw new Error(`A request to the Cloudflare API (${resource}) failed.`);
	}
}

/**
 * Make a fetch request to get the string content of a script, concatenated across modules
 */
export async function fetchScriptContent(
	resource: string,
	init: RequestInit = {},
	queryParams?: URLSearchParams,
	abortSignal?: AbortSignal
): Promise<string> {
	const response = await performApiFetch(
		resource,
		init,
		queryParams,
		abortSignal
	);

	console.debug(
		"-- START CF API RESPONSE:",
		response.statusText,
		response.status
	);

	console.debug("HEADERS:", { ...response.headers });
	// console.debug("RESPONSE:", text);
	console.debug("-- END CF API RESPONSE");
	const contentType = response.headers.get("content-type");

	const usesModules = contentType?.startsWith("multipart");
	if (usesModules && contentType) {
		const form = await formData(response);
		const entries = Array.from(form.entries());
		return entries.map((e) => e[1]).join("\n");
	} else {
		return await response.text();
	}
}

/**
 * Make a fetch request for a list of values,
 * extracting the `result` from the JSON response,
 * and repeating the request if the results are paginated.
 */
export async function fetchListResult<ResponseType>(
	resource: string,
	init: RequestInit = {},
	queryParams?: URLSearchParams
): Promise<ResponseType[]> {
	const results: ResponseType[] = [];
	let getMoreResults = true;
	let cursor: string | undefined;
	while (getMoreResults) {
		if (cursor) {
			queryParams = new URLSearchParams(queryParams);
			queryParams.set("cursor", cursor);
		}
		const json = await fetchInternal<FetchResult<ResponseType[]>>(
			resource,
			init,
			queryParams
		);
		if (json.success) {
			results.push(...json.result);
			if (hasCursor(json.result_info)) {
				cursor = json.result_info?.cursor;
			} else {
				getMoreResults = false;
			}
		} else {
			throw new Error(`A request to the Cloudflare API (${resource}) failed.`);
		}
	}
	return results;
}

function hasCursor(result_info: unknown): result_info is { cursor: string } {
	const cursor = (result_info as { cursor: string } | undefined)?.cursor;
	return cursor !== undefined && cursor !== null && cursor !== "";
}

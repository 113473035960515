import esbuild from "esbuild-wasm";
import type { Config } from "./config";
import type { WorkerRegistry } from "./dev-registry";
import type { Entry } from "./entry";

type StaticAssetsConfig =
	| (Config["assets"] & {
			bypassCache: boolean | undefined;
	  })
	| undefined;
async function initialise(wasmURL) {
	if (!window.ESBUILD_INITIALISOR) {
		window.ESBUILD_INITIALISOR = esbuild.initialize({
			wasmURL: wasmURL,
		});
	}
	await window.ESBUILD_INITIALISOR;
}
/**
 * Generate a bundle for the worker identified by the arguments passed in.
 */
export async function bundleWorker(
	entry: Entry,
	vfs: Record<string, string>,
	options: {
		serveAssetsFromWorker: boolean;
		assets: StaticAssetsConfig;
		betaD1Shims?: string[];
		jsxFactory: string | undefined;
		jsxFragment: string | undefined;
		rules: Config["rules"];
		watch?: esbuild.WatchMode;
		tsconfig: string | undefined;
		minify: boolean | undefined;
		nodeCompat: boolean | undefined;
		define: Config["define"];
		checkFetch: boolean;
		services: Config["services"] | undefined;
		workerDefinitions: WorkerRegistry | undefined;
		firstPartyWorkerDevFacade: boolean | undefined;
		targetConsumer: "dev" | "publish";
		local: boolean;
		testScheduled?: boolean | undefined;
		experimentalLocalStubCache: boolean | undefined;
	},
	wasmModule: unknown
): Promise<any> {
	const { jsxFactory, jsxFragment, watch, tsconfig, minify } = options;
	await initialise(wasmModule);

	const result = await esbuild.build({
		entryPoints: [entry.file],
		bundle: true,
		absWorkingDir: entry.directory,
		format: "esm",
		target: "es2020",
		sourcemap: true,
		minify,
		metafile: true,
		conditions: ["worker", "browser"],
		...(process.env.NODE_ENV && {
			define: {
				// use process.env["NODE_ENV" + ""] so that esbuild doesn't replace it
				// when we do a build of wrangler. (re: https://github.com/cloudflare/wrangler2/issues/1477)
				"process.env.NODE_ENV": `"${process.env["NODE_ENV" + ""]}"`,
				...options.define,
			},
		}),
		loader: {
			".js": "jsx",
			".mjs": "jsx",
			".cjs": "jsx",
		},
		plugins: [
			{
				name: "virtual-fs",
				setup(build) {
					const packages = JSON.parse(vfs["package.json"])["dependencies"];
					console.log(packages);

					build.onResolve(
						{ filter: /.*/, namespace: "skypack-ns" },
						async (args) => {
							console.log("resolve", args.namespace, args.path);

							const path = new URL(
								args.path,
								`https://cdn.skypack.dev/${args.importer}`
							);

							console.log(path.href);

							return {
								path: path.href,
								namespace: "skypack-ns",
							};
						}
					);
					build.onResolve({ filter: /.*/ }, async (args) => {
						console.log("resolve", args.namespace, args.path);
						if (packages[args.path]) {
							return {
								path: args.path,
								namespace: "skypack-ns",
								pluginData: packages[args.path],
							};
						}

						const path = new URL(args.path, `https://dummy${args.importer}`);

						console.log(path.pathname);

						return {
							path: path.pathname,
							namespace: "vfs-ns",
						};
					});

					build.onLoad(
						{ filter: /.*/, namespace: "skypack-ns" },
						async (args) => {
							console.log("load", args.namespace, args.path);
							const script = await (
								await fetch(
									args.path.startsWith("http")
										? args.path
										: `https://cdn.skypack.dev/${args.path}@${args.pluginData}`
								)
							).text();
							// console.log(
							//   vfs[args.path] ?? {
							//     contents: vfs[args.path],
							//     loader: args.path.split('.').slice(-1)[0]
							//   }
							// );

							return {
								contents: script,
								loader: "js",
							};
						}
					);
					// Load paths tagged with the "env-ns" namespace and behave as if
					// they point to a JSON file containing the environment variables.
					build.onLoad({ filter: /.*/, namespace: "vfs-ns" }, (args) => {
						console.log("load", args.namespace, args.path);
						// console.log(
						//   vfs[args.path] ?? {
						//     contents: vfs[args.path],
						//     loader: args.path.split('.').slice(-1)[0]
						//   }
						// );

						return (
							vfs[args.path] && {
								contents: vfs[args.path],
								loader: args.path.split(".").slice(-1)[0],
							}
						);
					});
				},
			},
		],
		...(jsxFactory && { jsxFactory }),
		...(jsxFragment && { jsxFragment }),
		...(tsconfig && { tsconfig }),
		watch,
	});

	return result;
}

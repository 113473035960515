import assert from "../assert";
import type { ApiCredentials } from "../user";
import type { URLSearchParams } from "node:url";
import type { RequestInit, HeadersInit } from "undici";

import token from "./token";

const wranglerVersion = "2.1.9";
/**
 * Get the URL to use to access the Cloudflare API.
 */
export const getCloudflareAPIBaseURL = () =>
	"https://api-proxy.r.workers.dev/client/v4/";

/*
 * performApiFetch does everything required to make a CF API request,
 * but doesn't parse the response as JSON. For normal V4 API responses,
 * use `fetchInternal`
 * */
export async function performApiFetch(
	resource: string,
	init: RequestInit = {},
	queryParams?: URLSearchParams,
	abortSignal?: AbortSignal
) {
	const method = init.method ?? "GET";
	assert(
		resource.startsWith("/"),
		`CF API fetch - resource path must start with a "/" but got "${resource}"`
	);
	const apiToken = token();
	const headers = cloneHeaders(init.headers);
	addAuthorizationHeaderIfUnspecified(headers, apiToken);
	addUserAgent(headers);

	const queryString = queryParams ? `?${queryParams.toString()}` : "";
	console.debug(
		`-- START CF API REQUEST: ${method} ${getCloudflareAPIBaseURL()}${resource}${queryString}`
	);
	console.debug("HEADERS:", JSON.stringify(headers, null, 2));
	console.debug("INIT:", JSON.stringify(init, null, 2));
	console.debug("-- END CF API REQUEST");
	return await fetch(`${getCloudflareAPIBaseURL()}${resource}${queryString}`, {
		method,
		...init,
		headers,
		signal: abortSignal,
	});
}

/**
 * Make a fetch request to the Cloudflare API.
 *
 * This function handles acquiring the API token and logging the caller in, as necessary.
 *
 * Check out https://api.cloudflare.com/ for API docs.
 *
 * This function should not be used directly, instead use the functions in `cfetch/index.ts`.
 */
export async function fetchInternal<ResponseType>(
	resource: string,
	init: RequestInit = {},
	queryParams?: URLSearchParams,
	abortSignal?: AbortSignal
): Promise<ResponseType> {
	const method = init.method ?? "GET";
	const response = await performApiFetch(
		resource,
		init,
		queryParams,
		abortSignal
	);
	const jsonText = await response.text();
	console.debug(
		"-- START CF API RESPONSE:",
		response.statusText,
		response.status
	);
	console.debug("HEADERS:", JSON.stringify(response.headers, null, 2));
	console.debug("RESPONSE:", jsonText);
	console.debug("-- END CF API RESPONSE");

	return JSON.parse(jsonText);
}

function cloneHeaders(
	headers: HeadersInit | undefined
): Record<string, string> {
	return headers instanceof Headers
		? Object.fromEntries(headers.entries())
		: Array.isArray(headers)
		? Object.fromEntries(headers)
		: { ...headers };
}

function addAuthorizationHeaderIfUnspecified(
	headers: Record<string, string>,
	auth: ApiCredentials
): void {
	if (!("Authorization" in headers)) {
		if ("apiToken" in auth) {
			headers["Authorization"] = `Bearer ${auth.apiToken}`;
		} else {
			headers["X-Auth-Key"] = auth.authKey;
			headers["X-Auth-Email"] = auth.authEmail;
		}
	}
}

function addUserAgent(headers: Record<string, string>): void {
	headers["User-Agent"] = `wrangler/${wranglerVersion}`;
}
